import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDocs } from "firebase/firestore";
import { addFeedback, auth } from "./database";
import { db } from "./database";
import { collection, query, where } from "firebase/firestore";
import Button from "./Common/Button";
import BatchAdd from "./BatchAdd";
function InsertionUI({ logout, username }) {
  const [value, setValue] = React.useState("");
  const [key, setKey] = React.useState(0);
  const [peopleArray, setPeopleArray] = React.useState([]);
  // const [email, setEmail] = React.useState("");
  const [feedback, setFeedback] = React.useState("");
  const [rawPeopleArray, setRawPeopleArray] = React.useState([]);
  const [selectedStudents, setSelectedStudents] = React.useState([]);
  const [score, setScore] = React.useState(0);
  const fetchKids = async () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const usersRef = collection(db, "users");
        const funPeopleArray = [];
        const funRawPeopleArray = [];
        var q = query(usersRef, where("admin", "==", false));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          let sampleDoc = doc.data();
          sampleDoc.id = doc.id;
          funRawPeopleArray.push(sampleDoc.username);
          //Push an object with id as the key and sampledoc as the value
          funPeopleArray.push(sampleDoc);
        });
        // console.log(funPeopleArray)
        setPeopleArray(funPeopleArray);
        setRawPeopleArray(funRawPeopleArray);
      }
    });
  };
  useEffect(() => {
    fetchKids();
  }, []);
  const handleChange = (event) => {
    setValue(event.target.value);
    setKey(event.target.key);
    console.log(event);
  };

  const handleSubmit = (event) => {
    if (selectedStudents.length !== 0) {
      selectedStudents.forEach((student) => {
        console.log(student);
        console.log("multiple sheep");
        addFeedback([
          {
            uid: student.id,
            feedback: feedback,
            score: score,
            username: username,
          },
        ]);
      });
    } else {
      event.preventDefault();
      console.log(value);
      addFeedback([
        {
          score: score === "" ? 0 : score,
          uid: peopleArray[rawPeopleArray.indexOf(value)].id,
          feedback: feedback,
          user: username,
        },
      ]);
    }
  };
  // console.log(logout);
  console.log("yes");
  return (
    <div className="AdminBox">
      <div className="flex justify-between logout-container">
        <h1>Create Feedback or Add Points</h1>
        {/* <Button
          title="Log out"
          handleAction={logout}
          style={{
            maxHeight: "3.0rem",
            fontSize: "13px",
            whiteSpace: "nowrap",
          }}
        ></Button> */}
      </div>
      {/* Create a select element with 3 options */}
      <br></br>
      <div className="grid grid-cols-2">
        {/* <div style={{display: 'flex'}}> */}
        <h2>How many points do you want to add?</h2>
        <TextField
          id="outlined-number"
          label="Number"
          type="number"
          onChange={(e) => {
            // if (e.target.value !== "") {
            // if (e.target.value >= 0) {
            setScore(e.target.value);
            // }
            //   setScore(e.target.value);
            // }
          }}
          value={score}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* </div> */}
        <div>
          <br></br>
        </div>
        <div>
          <br></br>
        </div>
        {selectedStudents.length == 0 ? (
          <>
            <h2>Who would you like to create points for?</h2>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              //   label="Age"
              onChange={handleChange}
            >
              {peopleArray.map((person, index) => {
                return (
                  <MenuItem value={person.username} key={index}>
                    {person.username}
                  </MenuItem>
                );
              })}
            </Select>
            <div>
              <br></br>
            </div>
            <div>
              <br></br>
            </div>
          </>
        ) : (
          <></>
        )}

        <h2>Any feedback?</h2>
        <TextField
          id="outlined-textbox"
          label="Feedback"
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
          value={feedback}
        />
        {/* </TextField> */}
      </div>

      <br></br>
      <div>
        <Button
          style={{ fontSize: "1.5rem" }}
          title={
            selectedStudents.length > 0
              ? "Send to All Selected Students"
              : "Submit"
          }
          handleAction={handleSubmit}
        />
      </div>
      <BatchAdd
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
      ></BatchAdd>
    </div>
  );
}

export default InsertionUI;
