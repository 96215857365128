// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDyw_HXUZO7X0PwYjWe_M34YuAOndcLOLM",
  authDomain: "dia-mun.firebaseapp.com",
  projectId: "dia-mun",
  storageBucket: "dia-mun.appspot.com",
  messagingSenderId: "284840994962",
  appId: "1:284840994962:web:542119a27d6ac95c6c5e4b"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);