import "./App.css";
import { useState, useEffect } from "react";
// import "./App.css";
import Form from "./Components/Common/Form";
import Home from "./Components/Home";
import { Routes, Route, useNavigate } from "react-router-dom";
import { app } from "./firebase-config";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createUser, getFeedback } from "./Components/database";
import InsertionUI from "./Components/insertionUI";
import ForgottenPassword from "./Components/ForgottenPassword";
import Settings from "./Components/Settings";

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [score, setScore] = useState(0);
  const [profilePicture, setProfilePicture] = useState(
    "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
  );
  let navigate = useNavigate();

  const handleAction = (id) => {
    const authentication = getAuth();
    if (id === 1) {
      console.log(authentication, email, password);
      signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          console.log(response);
          localStorage.setItem(
            "Auth Token",
            response._tokenResponse.refreshToken
          );
          setTimeout(() => {
            navigate("/2");
            navigate("/");
          }, 4000);
        })
        .catch((error) => {
          console.log(error.code);
          if (error.code === "auth/wrong-password") {
            toast.error("Please check the Password");
          }
          if (error.code === "auth/user-not-found") {
            toast.error("Please check the Email");
          }
          if (error.code === "auth/invalid-email") {
            toast.error("Please check the Email");
          }
        });
    }
    if (id === 2) {
      createUserWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          createUser(username, email, profilePicture, fullName);
          navigate("/");
          localStorage.setItem(
            "Auth Token",
            response._tokenResponse.refreshToken
          );
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            toast.error("Email Already in Use");
          }
        });
    }
  };

  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/");
    }
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("Auth Token");
    navigate("/login");
    toast("Sussessfully Logged out");
  };
  return (
    <div className="App">
      <>
        <ToastContainer />
        {/* <label className=' border-l-dark-900 font-extrabold'>
          "hello"
        </label> */}
        {localStorage.getItem("Auth Token") ? (<div className="nav-bar flex justify-between">
          <div className="flex">
            <img
              src="https://static.wixstatic.com/media/eea8d2_b9a67766f3c147a3956d382833f0996d~mv2.png"
              height="80"
              alt="tutors logo"
            ></img>
            <button
              className="nav-bar-button"
              onClick={() => {
                window.open("https://diamun.org");
              }}
              title="Diamun"
            >
              Diamun
            </button>
          </div>
          <div className="flex">
            <button
              className="nav-bar-button"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </button>
            <button
              className="nav-bar-button"
              onClick={() => {
                navigate("/settings");
              }}
            >
              Settings
            </button>
            <button className="nav-bar-button" onClick={handleLogout}>
              Log Out
            </button>
          </div>
        </div>): null}
        <Routes>
          <Route
            path="/login"
            element={
              <Form
                title="Login"
                setEmail={setEmail}
                setPassword={setPassword}
                handleAction={() => handleAction(1)}
                option={() => navigate("/register")}
                setUsername={() => {}}
                setProfilePicture={() => {}}
                setFullName={() => {}}
                actualEmail={email}
                forgotPassword={() => {
                  navigate("/forgotPassword");
                }}
              />
            }
          />
          <Route
            path="/register"
            element={
              <Form
                title="Register"
                setEmail={setEmail}
                setPassword={setPassword}
                handleAction={() => handleAction(2)}
                option={() => navigate("/login")}
                setUsername={setUsername}
                setProfilePicture={setProfilePicture}
                setFullName={setFullName}
                actualEmail={email}
                // forgotPassword= {()=>{navigate("/forgotPassword")}}
              />
            }
          />
          <Route
            path="/settings"
            element={
              <Settings returnToLogin={() => navigate("/login")}></Settings>
            }
          ></Route>
          <Route
            path="/forgotPassword"
            element={
              <ForgottenPassword
                returnToLogin={() => navigate("/login")}
              ></ForgottenPassword>
            }
          />
          <Route path="/" element={<Home />} />
          {/* <Route path="/insertion" element={<InsertionUI/>} /> */}
        </Routes>
      </>
    </div>
  );
}

export default App;

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link font-bold"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
