import { Add, CheckBox, Remove } from "@mui/icons-material";
import { Input, MenuItem, Select } from "@mui/material";
import React from "react";
import { fullClassesList } from "./classLists";
import { fetchClassesList } from "./database";
const BatchAddUI = ({ setSelectedStudents, selectedStudents }) => {
  const [show, setShow] = React.useState(false);
  const [classes, setClasses] = React.useState(fullClassesList);
  const [classesList, setClassesList] = React.useState([]);
  //   const [selectedStudents, setSelectedStudents] = React.useState([]);
  const [selectedClass, setSelectedClass] =
    React.useState("elephantPurpleMoss");

  React.useEffect(() => {
    console.log(selectedStudents);
  }, [selectedStudents]);
  React.useEffect(() => {
    setSelectedStudents([]);
    console.log("fetching classes");
    if (selectedClass !== "") {
      fetchClassesList(selectedClass)
        .then((classes) => {
          console.log(classes);
          if (!classes) {
            setClassesList([]);
            return;
          }
          console.log(classesList);
          setClassesList(classes);
        })
        .catch((err) => {});
    }
  }, [selectedClass]);

  return (
    <div id="batchinsertionsubui">
      <div className="flex p-2">
        <Select
          labelId="user-select-label"
          id="user-select"
          value={selectedClass}
          label="Select Class"
          onChange={(e) => {
            setSelectedClass(e.target.value);
          }}
        >
          {classes.map((classObj) => {
            return (
              <MenuItem value={classObj.classCode}>
                {classObj.name}: {classObj.classCode}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      {/* Generate a table that the user can select items using checkboxes of  */}
      <table>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Student Email</th>
            <th>Student Score</th>
            <th> Student Username</th>
            <th>Select</th>
            {/* <th>Student Class</th> */}
          </tr>
        </thead>
        <tbody>
          {classesList.map((classObj) => {
            return (
              <tr>
                <td>{classObj.fullName}</td>
                <td>{classObj.email}</td>
                <td>{classObj.score}</td>
                <td>{classObj.username.replace(/_[0-9]*/g, "")}</td>
                {/* <td>{classObj.classCode}</td> */}
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      console.log(classObj);
                      if (e.target.checked) {
                        setSelectedStudents([...selectedStudents, classObj]);
                      } else {
                        setSelectedStudents(
                          selectedStudents.filter((student) => {
                            return student.username !== classObj.username;
                          })
                        );
                      }
                    }}
                  ></input>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default BatchAddUI;
