export const fullClassesList = [
  {
    name: "Samarth Gupta",
    classCode: "elephantPurpleMoss",
    level: "intermediate",
  },
  { name: "Yagiz Ozenci", classCode: "redBottomedFrog", level: "beginner" },
  {
    name: "Kshitij Kant",
    classCode: "whiteHonorablePenguin",
    level: "beginner",
  },
  //   { name: "Krrish Aggarwal", classCode: "yellowBackTurtle" },
  { name: "Aazaam Alam", classCode: "blueBandedPenguin", level: "beginner" },
  {
    name: "Varshita Prasad",
    classCode: "doubleHumpedGreenCamel",
    level: "beginner",
  },
  { name: "Neeraj Shah", classCode: "blackAndWhiteTiger", level: "advanced" },
  { name: "Anoushka Rose", classCode: "greenBlueAnteater", level: "beginner" },
];

export const intermediateClassCodeList = [
  "elephantPurpleMoss",
  "ELEPHANTPURPLEMOSS",
  "elephantpurplemoss",
];

export const beginnerClassCodeList1 = [
  "blueBandedPenguin",
  "BLUEBANDEDPENGUIN",
  "bluebandedpenguin",
  "doubleHumpedGreenCamel",
  "DOUBLEHUMPEDGREENCAMEL",
  "doublehumpedgreencamel",
  "greenBlueAnteater",
  "GREENBLUEANTEATER",
  "greenblueanteater",
];
export const beginnerClassCodeList2 = [
  "redBottomedFrog",
  "REDBOTTOMEDFROG",
  "redbottomedfrog",
  "whiteHonorablePenguin",
  "WHITEHONORABLEPENGUIN",
  "whitehonorablepenguin",
];
export const fullBeginnerClassCodeList = [
  ...beginnerClassCodeList1,
  ...beginnerClassCodeList2,
];
export const advancedClassCodeList = [
  "blackAndWhiteTiger",
  "BLACKANDWHITETIGER",
  "blackandwhitetiger",
];

export const allClassCodeList = [
  ...fullBeginnerClassCodeList,
  ...intermediateClassCodeList,
  ...advancedClassCodeList,
];

export const checkIfClassCodeExists = (classCode) => {
  return allClassCodeList.includes(classCode);
};
