import "firebase/firestore";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  arrayUnion,
  updateDoc,
  increment,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
// import { collection, addDoc } from "firebase/firestore";
import { getDoc, doc, setDoc } from "firebase/firestore";
import * as firebase from "firebase/app";

import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateEmail,
} from "firebase/auth";
import { app } from "../firebase-config";
import { toast } from "react-toastify";
import {
  advancedClassCodeList,
  allClassCodeList,
  beginnerClassCodeList1,
  beginnerClassCodeList2,
  checkIfClassCodeExists,
  fullBeginnerClassCodeList,
  intermediateClassCodeList,
} from "./classLists";

export const db = getFirestore(app);

const initialProfileURL =
  "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg";
// Fetch scores

// Create user section in database. Name the doc the user's name
export const auth = getAuth();

export const checkExistingUser = async (username) => {
  const usersRef = collection(db, "users");
  var q = query(usersRef, where("username", "==", username));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    console.log("No matching documents.");
    return false;
  } else {
    console.log("User already exists");
    return true;
  }
};

//Handle forgotten password
export const handleForgotPassword = async (email) => {
  const auth = getAuth();
  const emailAddress = email;
  console.log(emailAddress);
  try {
    await sendPasswordResetEmail(auth, emailAddress);
    toast.success("Password reset email sent");
    toast("Make sure you check your spam folder");
    return true;
  } catch (error) {
    toast.error("Error sending password reset email");
    return false;
  }
};

export const handleEmailChange = async (email) => {
  const user = auth.currentUser;
  const usersRef = collection(db, "users");
  var q = query(usersRef, where("email", "==", email));
  let querySnapshot = undefined;
  try {
    querySnapshot = await getDocs(q);
  } catch (error) {
    toast.error(error);
  }
  if (querySnapshot.empty) {
    console.log("No matching documents.");
    try {
      try {
        updateEmail(auth.currentUser(), email)
          .then(async () => {
            toast("Check your email to verify your new email address!");
            await updateDoc(doc(db, "users", user.uid), {
              email: email,
              logs: arrayUnion((await getUserDetails()).email),
            });
          })
          .catch((error) => {
            toast.error(error);
          });
      } catch (error) {
        toast(error);
      }
      toast.success("Email changed");
      return true;
    } catch (error) {
      toast.error("Error changing email");
      return false;
    }
  } else {
    console.log("User already exists");
    toast.error("Email already exists, please try another one");
    return false;
  }
};
//Handle changing username
export const handleUsernameChange = async (username) => {
  const user = auth.currentUser;
  const usersRef = collection(db, "users");
  var q = query(usersRef, where("username", "==", username));
  let querySnapshot = undefined;
  try {
    querySnapshot = await getDocs(q);
  } catch (error) {
    toast.error(error);
  }
  if (querySnapshot.empty) {
    console.log("No matching documents.");
    try {
      await updateDoc(doc(db, "users", user.uid), {
        username: username,
      });
      toast.success("Username changed");
      return true;
    } catch (error) {
      toast.error("Error changing username");
      return false;
    }
  } else {
    console.log("User already exists");
    toast.error("Username already exists, please try another one");
    return false;
  }
};

//Handle classCode change
export const handleClassCodeChange = async (classCode) => {
  const user = auth.currentUser;
  await updateDoc(doc(db, "users", user.uid), {
    classCode: classCode,
  });
};
// Create user section in database. Name the doc the user's name
export const createUser = async (
  username,
  email,
  fullName,
  profileURL = initialProfileURL
) => {
  // await setDoc(doc(db, "cities", "LA"), {
  //     name: "Los Angeles",
  //     state: "CA",
  //     country: "USA"
  //   });

  await setDoc(doc(db, "users", `${getAuth().currentUser.uid}`), {
    username: username,
    email: email,
    profileURL: fullName,
    fullName: profileURL,
    score: 0,
    admin: false,
  });
  //     setDoc(getAuth().currentUser.uid({
  //     username: username,
  //     email: email,
  //     profileURL: profileURL,
  //   }));
};

export const fetchClassesList = async (classCode) => {
  console.log("querySnapshot");
  const classesRef = collection(db, "users");
  //query for docs where classCode = classCode
  var q = query(
    classesRef,
    where("classCode", "in", [
      classCode,
      classCode.toLowerCase(),
      classCode.toUpperCase(),
    ])
  );
  const querySnapshot = await getDocs(q);
  console.log(querySnapshot);
  if (querySnapshot.empty) {
    console.log("No matching documents.");
    return false;
  } else {
    console.log("User already exists");
    let classes = [];
    querySnapshot.forEach((doc) => {
      let docDataBrief = doc.data();
      //Get document name
      docDataBrief["id"] = doc.id;
      classes.push(docDataBrief);
    });
    console.log(classes);
    console.log("hi");
    return classes;
    //return the list of classes that match the classCode
  }
};

// Add feedback/score log to the user
export const addFeedback = async (feedbackArray) => {
  const feedback = feedbackArray[0];
  console.log(feedback.uid);
  const docRef = doc(db, "users", `${feedback.uid}`);
  console.log(feedback);
  console.log(docRef);
  let yourDate = new Date();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  const currentDate = yourDate.toISOString().split("T")[0];
  updateDoc(docRef, {
    feedback: arrayUnion(
      ...[
        {
          feedback: `${feedback.feedback}`,
          score: `${feedback.score}`,
          date: `${currentDate}`,
          createdBy: `${feedback.username}`,
        },
      ]
    ),
    score: increment(feedback.score),
  });
  toast("Feedback added successfully");
};

//Fetch feedback/score log
export const getFeedback = async () => {};
// catch (error) {
//     console.log(error);
// }
// }, 1000);
// }

// Add feedback/score log to the user

// export const addFeedback = async (feedback, points, uid) => {

// Fetch the leaderboard
export const getLeaderboard = async (isAdmin, classCode) => {
  const leaderboard = [];
  let data = await getUserDetails();
  // let classCode = data.classCode;
  // let isAdmin = data.admin;
  console.log(isAdmin, classCode);
  const usersRef = collection(db, "users");
  let q;
  let q1;
  console.log(classCode);
  //if classcode is not set, return an empty array

  console.log("this is teh data matey!");
  console.log(data);
  //Search users where admin == false and classCode is either beginner, intermediate or advanced
  if (isAdmin === true) {
    //Pass back an an array in the form [{level: beginner, data: []},{ level: intermediate, data: []}, {level: advanced, data: []}]
    let beginner = [];
    let intermediate = [];
    let advanced = [];
    console.log("New veginnering");
    let beginnerQ1 = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", beginnerClassCodeList1)
    );
    let beginnerQ2 = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", beginnerClassCodeList2)
    );
    let intermediateQ = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", intermediateClassCodeList)
    );
    let advancedQ = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", advancedClassCodeList)
    );
    const beginnerQuerySnapshot1 = await getDocs(beginnerQ1);
    const beginnerQuerySnapshot2 = await getDocs(beginnerQ2);
    const intermediateQuerySnapshot = await getDocs(intermediateQ);
    const advancedQuerySnapshot = await getDocs(advancedQ);
    beginnerQuerySnapshot1.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      beginner.push(doc.data());
    });
    beginnerQuerySnapshot2.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      beginner.push(doc.data());
    });

    intermediateQuerySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      intermediate.push(doc.data());
    });
    advancedQuerySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      advanced.push(doc.data());
    });
    console.log(beginner);
    console.log("beginner");
    leaderboard.push({ level: "beginner", data: beginner });
    leaderboard.push({ level: "intermediate", data: intermediate });
    leaderboard.push({ level: "advanced", data: advanced });

    return leaderboard;
  }
  if (!checkIfClassCodeExists(classCode)) {
    return undefined;
  }
  if (fullBeginnerClassCodeList.includes(classCode)) {
    q = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", beginnerClassCodeList1)
    );
    q1 = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", beginnerClassCodeList2)
    );
  } else if (intermediateClassCodeList.includes(classCode)) {
    q = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", intermediateClassCodeList)
    );
  } else if (advancedClassCodeList.includes(classCode)) {
    q = query(
      usersRef,
      where("admin", "==", false),
      where("classCode", "in", advancedClassCodeList)
    );
  } else {
    return null;
  }

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    leaderboard.push(doc.data());
  });
  if (q1 !== undefined) {
    const querySnapshot1 = await getDocs(q1);
    querySnapshot1.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      leaderboard.push(doc.data());
    });
  }

  console.log(leaderboard);
  console.log(Leaderboard);
  return leaderboard;
};
//Fetch user details
export const getUserDetails = async () => {
  const user = auth.currentUser;
  const docRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};
//Update the user's profile picture

export const Leaderboard = [
  {
    name: "Shawn Hanna",
    username: "@shawnhanna",
    score: 1550,
    img: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    dt: "2022-02-10",
  },
  {
    name: "Fidel Hand",
    username: "@fidelhand",
    score: 2310,
    img: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    dt: "2021-01-01",
  },
  {
    name: "Bessie Hickle",
    username: "@bessiehickle",
    score: 350,
    img: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    dt: "2021-08-17",
  },
  {
    name: "Adella Wunsch",
    username: "@adellawunsch",
    score: 2100,
    img: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    dt: "2021-10-23",
  },
  {
    name: "Clair O'Connell",
    username: "@clairoconnell",
    score: 1250,
    img: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    dt: "2022-01-22",
  },
  {
    name: "Kameron Prosacco",
    username: "@kameronprosacco",
    score: 5250,
    img: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    dt: "2022-01-21",
  },
];
