import { Add, Remove } from "@mui/icons-material";
import React from "react";
import BatchAddUI from "./BatchAddUI";
const BatchAdd = ({ setSelectedStudents, selectedStudents }) => {
  const [show, setShow] = React.useState(false);
  return (
    <div id="batchinsertion">
      <br></br>
      <div
        className="titular_batchadd flex"
        onClick={() => {
          setShow(!show);
          console.log("changed");
        }}
      >
        <h1>Batch Add</h1>
        {show ? <Remove></Remove> : <Add></Add>}
      </div>
      {show ? (
        <BatchAddUI
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
        ></BatchAddUI>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BatchAdd;
