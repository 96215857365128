import { TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import BasicButtons from "./Common/Button";
import { handleForgotPassword } from "./database";
import { ValidateEmail } from "./utils";
function ForgottenPassword({ returnToLogin }) {
  const [email, setEmail] = React.useState("");
  const [emailValidty, setEmailValidity] = React.useState(true);
  const [emailFirstChange, setEmailFirstChange] = React.useState(true);

  const checkEmail = async (email) => {
    if (emailFirstChange) {
      setEmailFirstChange(false);
    }
    if (ValidateEmail(email)) {
      setEmailValidity(true);
    } else {
      setEmailValidity(false);
    }
    setEmail(email);
  };
  const forgotPassword = async () => {
    if (emailValidty) {
      await handleForgotPassword(email).then((res) => {
        if (res) {
          // toast.success("Password reset email sent");
          // toast("Make sure you check your spam folder");
          returnToLogin();
        }
      });
    } else {
      toast.error("Invalid email");
    }
  };

  return (
    <div>
      <img
        alt="diamun logo"
        height="200px"
        src="https://static.wixstatic.com/media/0cff90_85854762453944e4ae99e81cd4b25e61~mv2.png/v1/crop/x_547,y_1062,w_1383,h_1339/fill/w_620,h_600,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/DIAMUN%202023%20no%20bg%20logo.png"
      ></img>
      <div className="heading-container">
        <h2>Reset Your Password</h2>
        <br></br>
      </div>
      <TextField
        id="outlined-basic"
        label="Enter the Email"
        variant="outlined"
        error={!emailValidty && !emailFirstChange}
        onChange={(e) => checkEmail(e.target.value)}
      ></TextField>
      <br></br>
      <br></br>
      <BasicButtons
        title="Reset Password"
        handleAction={forgotPassword}
      ></BasicButtons>
      <br></br>
      <br></br>
      <BasicButtons
        title="Return to Login"
        handleAction={returnToLogin}
      ></BasicButtons>
    </div>
  );
}

export default ForgottenPassword;
