import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PersonalData from "./PersonalData";
import "../App.css";
import Board from "./board";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { getFeedback } from "./database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import InsertionUI from "./insertionUI";
import { auth } from "./database";
import { db } from "./database";
import { toast } from "react-toastify";
// const db = getFirestore(app);
import Button from "./Common/Button";
// const auth = getAuth();

export default function Home() {
  const [feedback, setFeedbacks] = React.useState([]);
  const [username, setUsername] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [classCode, setClassCode] = React.useState("");
  const [adminTrue, setAdminTrue] = React.useState(false);
  const [userScore, setUserScore] = React.useState(0);
  const [renderBoard, setRenderBoard] = React.useState(false);
  const [profilePicture, setProfilePicture] = React.useState("");
  const handleLogout = () => {
    localStorage.removeItem("Auth Token");
    navigate("/login");
    toast("Sussessfully Logged out");
  };

  let navigate = useNavigate();
  const manageFeedback = async () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log(auth)
        const docRef = doc(db, "users", `${await auth.currentUser.uid}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());

          const data = docSnap.data();
          // setEmail(data.email);
          console.log(data.feedback);
          setUsername(data.username);
          // setClassCode(data.kjsndkjdsjk);
          setProfilePicture(data.profileURL);
          setUserScore(data.score);
          setFeedbacks(data.feedback);
          setFullName(data.fullName);
          setClassCode(data.classCode);
          console.log(data);
          console.log("classcode");
          console.log(data.classCode);
          setAdminTrue(data.admin);
          setRenderBoard(true);
        }
        //     else {
        //         toast.error("No such document!");
        //         // doc.data() will be undefined in this case
        //     }
        // } else {
        // No user is signed in.
      }
    });
    // const data = await getFeedback();
  };
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");
    // console.log(authToken);
    if (authToken) {
      navigate("/");
      manageFeedback();
    }

    if (!authToken) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      {/* Home Page */}
      {/* nav bar */}
      {/* <div className="nav-bar flex justify-between">
        <div className="flex">
          <img
            src="https://static.wixstatic.com/media/eea8d2_b9a67766f3c147a3956d382833f0996d~mv2.png"
            height="80"
            alt="tutors logo"
          ></img>
          <button className="nav-bar-button"
            onClick={() => {
              window.open("https://diamun.org");
            }}
            title="Diamun"
          >
            Diamun
          </button>
        </div>
        <div className="flex">
          <button  className="nav-bar-button" onClick={()=>{navigate("/settings")}}>Settings</button>
          <button  className="nav-bar-button" onClick={handleLogout}>Log Out</button>
        </div>
      </div> */}
      <div className="grid grid-cols-3">
        <div className="col-span-2">
          {!adminTrue ? (
            <PersonalData
              feedback={feedback}
              username={username}
              userScore={userScore}
              profilePicture={profilePicture}
              fullName={fullName}
              logout={handleLogout}
            ></PersonalData>
          ) : (
            <InsertionUI
              logout={handleLogout}
              username={username}
            ></InsertionUI>
          )}
        </div>
        {/* <div className="h-1"> */}
        {/* <Leaderboard></Leaderboard> */}
        {adminTrue && renderBoard ? <Board classCode={classCode} isAdminTruly={adminTrue}></Board> : <h3>Oops, you don't have access to this</h3>}

        {/* </div> */}
      </div>
    </div>
  );
}
