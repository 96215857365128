import React, { useEffect, useState } from "react";
import Profiles from "./profiles";
import { Leaderboard, getLeaderboard } from "./database";
import { allClassCodeList, checkIfClassCodeExists } from "./classLists";

export default function Board({ isAdminTruly, classCode }) {
  const [leaderboard, setLeaderboard] = useState([]);
  const [period, setPeriod] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const setLeaderboardProperly = async () => {
    const leaderboardRaw = await getLeaderboard(isAdminTruly, classCode);
    setLeaderboard(leaderboardRaw);
    //if leaderboard[1] is of type object, then set administrator to true
    console.log(leaderboardRaw);
    console.log("leaderboardRaw");
    try {
      leaderboardRaw[0].level === "beginner"
        ? setIsAdmin(true)
        : setIsAdmin(false);
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(isAdmin)
  // console.log(classCode)
  // console.log("allClassCodeList")
  useEffect(() => {
    setLeaderboardProperly();
  }, []);

  useEffect(() => {
    console.log("leaderboard2323");
    console.log(leaderboard);
    console.log("leaderboard");
  }, [leaderboard]);
  const handleClick = (e) => {
    setPeriod(e.target.dataset.id);
    console.log(period);
    const beginnerButton = document.getElementById("leaderboardBeginnerButton");
    const intermediateButton = document.getElementById(
      "leaderboardIntermediateButton"
    );
    const advancedButton = document.getElementById("leaderboardAdvancedButton");
    e.target.dataset.id === 0
      ? beginnerButton.classList.add("active")
      : beginnerButton.classList.remove("active");
    e.target.dataset.id === 1
      ? intermediateButton.classList.add("active")
      : intermediateButton.classList.remove("active");
    e.target.dataset.id === 2
      ? advancedButton.classList.add("active")
      : advancedButton.classList.remove("active");
  };

  return (
    <div className="board">
      <h1 className="leaderboard">Leaderboard</h1>

      {/* Temporarily Hide Duration Control */}
      {isAdmin ? (
        <div className="duration">
          <button
            onClick={handleClick}
            data-id="0"
            id="leaderboardBeginnerButton"
          >
            Beginner
          </button>
          <button
            onClick={handleClick}
            data-id="1"
            id="leaderboardIntermediateButton"
          >
            Intermediate
          </button>
          <button
            onClick={handleClick}
            data-id="2"
            id="leaderboardAdvancedButton"
          >
            Advanced
          </button>
        </div>
      ) : (
        <></>
      )}

      {isAdmin ? (
        <Profiles Leaderboard={between(leaderboard[period].data, 0)}></Profiles>
      ) : leaderboard !== undefined && leaderboard?.length > 1 ? (
        <Profiles Leaderboard={between(leaderboard, 0)}></Profiles>
      ) : (
        <h2 className="leaderboard">Error: No class could be found</h2>
      )}
      {/* {leaderboard != undefined && leaderboard.length > 0 ? <Profiles Leaderboard={between(leaderboard, period)}></Profiles> : <h2 className='leaderboard'>Error: No class could be found</h2> */}
    </div>
  );
}

function between(data, between) {
  const today = new Date();
  const previous = new Date(today);
  previous.setDate(previous.getDate() - (between + 1));

  let filter = data.filter((val) => {
    let userDate = new Date(val.dt);
    if (between === 0) return val;
    return previous <= userDate && today >= userDate;
  });

  // sort with asending order
  return filter.sort((a, b) => {
    if (a.score === b.score) {
      return b.score - a.score;
    } else {
      return b.score - a.score;
    }
  });
}
