import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "./Button";
import { ValidateEmail } from "../utils";
import { toast } from "react-toastify";
import { checkExistingUser, handleForgotPassword } from "../database";
import { CircularProgress } from "@mui/material";

// ValidateEmail("");

export default function BasicTextFields({
  title,
  setPassword,
  setEmail,
  handleAction,
  option,
  setUsername,
  setProfilePicture,
  setFullName,
  actualEmail,
  forgotPassword,
}) {
  const [emailValidty, setEmailValidity] = React.useState(true);
  const [emailFirstChange, setEmailFirstChange] = React.useState(true);
  const [passwordValidty, setPasswordValidity] = React.useState(true);
  const [passwordFirstChange, setPasswordFirstChange] = React.useState(true);
  const [usernameValidty, setUsernameValidity] = React.useState(true);
  const [usernameFirstChange, setUsernameFirstChange] = React.useState(true);
  const [fullNameValidty, setFullNameValidity] = React.useState(true);
  const [fullNameFirstChange, setFullNameFirstChange] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);
  //Make showLoading accessible to the parent component
  // const [hidePassword, setHidePassword] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {

    setShowLoading(false);
    }, 6000);
  }, [showLoading]);

  // const forgotPassword = async () => {
  //   if (emailValidty) {
  //     await handleForgotPassword(actualEmail);
  //   } else {
  //     toast.error("Invalid email");
  //   }
  // }
  const checkFullName = (fullName) => {
    if (fullNameFirstChange) {
      setFullNameFirstChange(false);
    }
    if (fullName.length < 3) {
      setFullNameValidity(false);
    } else {
      setFullNameValidity(true);
    }
    setFullName(fullName);
  };

  const checkUsername = async (username) => {
    if (usernameFirstChange) {
      setUsernameFirstChange(false);
    }
    if (username.length < 3) {
      setUsernameValidity(false);
      // toast.error("Username already exists! Please try another one.");
    } else {
      setUsernameValidity(true);
    }
    setUsername(username + "_" + crypto.getRandomValues(new Uint32Array(4))[0]);
    //Get 4 random numbers
  };

  const checkValuesAndSubmit = () => {
    if (option === "signup") {
      if (
        emailValidty &&
        passwordValidty &&
        usernameValidty &&
        fullNameValidty &&
        !emailFirstChange &&
        !passwordFirstChange &&
        !usernameFirstChange &&
        !fullNameFirstChange
      ) {
        handleAction();
        setShowLoading(true);
      } else {
        toast.error("Please enter valid values!");
      }
    } else {
      if (
        emailValidty &&
        passwordValidty &&
        !emailFirstChange &&
        !passwordFirstChange
      ) {
        handleAction();
        setShowLoading(true);
      } else {
        toast("One or more fields were not filled! Or are invalid!", {
          type: "error",
        });
      }
    }
  };
  const properlySetPassword = (password) => {
    setPasswordFirstChange(false);
    setPassword(password);
    if (password.length < 8) {
      setPasswordValidity(false);
    } else {
      setPasswordValidity(true);
    }
  };
  const properlySetFullEmail = (email) => {
    setEmailFirstChange(false);
    setEmail(email);
    if (ValidateEmail(email)) {
      setEmailValidity(true);
    } else {
      setEmailValidity(false);
    }
  };
  return (
    <div>
      <img
        alt="diamun logo"
        height="200px"
        src="https://static.wixstatic.com/media/0cff90_85854762453944e4ae99e81cd4b25e61~mv2.png/v1/crop/x_547,y_1062,w_1383,h_1339/fill/w_620,h_600,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/DIAMUN%202023%20no%20bg%20logo.png"
      ></img>
      <div className="heading-container">
        <h3>{title} Form</h3>
      </div>

      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="email"
          label="Enter the Email"
          variant="outlined"
          error={!emailValidty && !emailFirstChange}
          onChange={(e) => properlySetFullEmail(e.target.value)}
        />
        <br></br>
        {/* {!hidePassword ? ( */}
        <TextField
          id="password"
          label="Enter the Password"
          variant="outlined"
          type="password"
          error={!passwordValidty && !passwordFirstChange}
          onChange={(e) => properlySetPassword(e.target.value)}
        />
        {/* ): null} */}
        <br></br>
        {title !== "Login" ? (
          <TextField
            id="select-username"
            label="Enter the Username"
            variant="outlined"
            error={!usernameValidty && !usernameFirstChange}
            onChange={(e) => checkUsername(e.target.value)}
          />
        ) : null}
        <br></br>
        {title !== "Login" ? (
          <TextField
            id="select-fullname"
            label="Enter the Full Name"
            variant="outlined"
            error={!fullNameValidty && !fullNameFirstChange}
            onChange={(e) => checkFullName(e.target.value)}
          />
        ) : null}

        {/* <br></br>
        {title !== "Login" ? (
          <TextField
            id="select-profile-picture"
            label="Select Profile Picture"
            variant="outlined"
            onChange={(e) => setProfilePicture("")}
          />
        ) : null} */}
        <br></br>
      </Box>

      <Button title={title} handleAction={checkValuesAndSubmit} />
      {showLoading ? <><br></br><br></br><CircularProgress></CircularProgress></> : null}
      <br></br>
      <br></br>

      <Button
        handleAction={() => {
          option();
          toast(title + " in progress");
        }}
        title={`Or ${title !== "Login" ? "Login" : "Register"} Instead?`}
      ></Button>
      <br></br>
      <br></br>
      {title === "Login" ? (
        <Button
          title="Forgot Password?"
          handleAction={() => {
            forgotPassword();
          }}
        ></Button>
      ) : null}
    </div>
  );
}
