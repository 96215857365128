import { CancelOutlined, CheckCircleOutlineRounded } from "@mui/icons-material";
import { Button, Icon, IconButton, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import BasicButtons from "./Common/Button";
import {
  getUserDetails,
  handleClassCodeChange,
  handleEmailChange,
  handleUsernameChange,
} from "./database";

function Settings({ returnToLogin }) {
  const [email, setEmail] = React.useState();
  const [username, setUsername] = React.useState("");
  const [profilePicture, setProfilePicture] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [currentlyEditing, setCurrentlyEditing] = React.useState("");
  const [classCode, setClassCode] = React.useState("");
  //   console.log(data)

  const resetUserDeets = () => {
    getUserDetails().then((data) => {
      console.log(data)
      setEmail(data.email);
      setUsername(data.username);
      setProfilePicture(data.profileURL);
      setFullName(data.fullName);
      setClassCode(data.classCode);
    })
  }
  useEffect(() => {
    // setEmail(
      resetUserDeets()
    // );
    let authToken = localStorage.getItem("Auth Token");
    // console.log(authToken);
    // if (authToken) {
    //   navigate("/");
    //   manageFeedback();
    // }

    if (!authToken) {
      returnToLogin("/login");
    }
  }, []);
  return (
    <div className="settings-container">
      <h1>Settings</h1>
      {/* <br></br>

      <div className="field-change-container">
        <h2>Email</h2>

        <div style={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Email"
            // variant="disabled"
            disabled={currentlyEditing === "email" ? false : true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // />
          ></TextField>
          {currentlyEditing === "email" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <>
                <Button
                  title="Save"
                  onClick={async() => {
                    if (await handleEmailChange(email) !== false){;
                    setCurrentlyEditing()} else {
                      resetUserDeets()
                    };
                  }}
                >
                  <CheckCircleOutlineRounded></CheckCircleOutlineRounded>
                </Button>
                <Button
                  title="Cancel"
                  onClick={() => setCurrentlyEditing("ss")}
                >
                  <CancelOutlined></CancelOutlined>
                </Button>
              </>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <BasicButtons
                title="Edit"
                //   style={{ marginLeft: "10px" }}
                handleAction={() => setCurrentlyEditing("email")}
              ></BasicButtons>
            </div>
          )}
        </div>
      </div> */}
      <br></br>
      <div className="field-change-container">
        <h2>Username</h2>
        <div style={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Username"
            // variant="disabled"
            disabled={currentlyEditing === "username" ? false : true}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            // />
          ></TextField>
          {currentlyEditing === "username" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <>
                <Button
                  title="Save"
                  onClick={async() => {
                    if (await handleUsernameChange(username) !== false){;
                    setCurrentlyEditing()} else {
                      toast.error("An error occured with this operation")
                      resetUserDeets()
                    };
                  }}
                >
                  <CheckCircleOutlineRounded></CheckCircleOutlineRounded>
                </Button>
                <Button
                  title="Cancel"
                  onClick={() => setCurrentlyEditing("ss")}
                >
                  <CancelOutlined></CancelOutlined>
                </Button>
              </>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <BasicButtons
                title="Edit"
                //   style={{ marginLeft: "10px" }}
                handleAction={() => setCurrentlyEditing("username")}
              ></BasicButtons>
            </div>
          )}
        </div>
      </div>
      <br></br>
      <div className="field-change-container">
        <h2>Class Code</h2>
        <div style={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label="Class Code"
            // variant="disabled"
            disabled={currentlyEditing === "classCode" ? false : true}
            value={classCode}
            onChange={(e) => setClassCode(e.target.value)}
            // />
          ></TextField>
          {currentlyEditing === "classCode" ? 
            <div style={{ display: "flex", alignItems: "center" }}>
              <>

                <Button
                  title="Save"
                  onClick={() => {
                    setCurrentlyEditing()
                  handleClassCodeChange(classCode)}}
                >
                  <CheckCircleOutlineRounded></CheckCircleOutlineRounded>
                </Button>
                <Button
                  title="Cancel"
                  onClick={() => setCurrentlyEditing("ss")}
                >
                  <CancelOutlined></CancelOutlined>
                </Button>
              </> </div>: <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <BasicButtons
                title="Edit"
                //   style={{ marginLeft: "10px" }}
                handleAction={() => setCurrentlyEditing("classCode")}
              ></BasicButtons>
            </div>}
        </div>
      </div>
      {/* <br></br>
      <div className="field-change-container">
        <h2>Profile Picture</h2>
        <div style={{ display: "flex" }}>
          <input type={"file"} onChange={ev => {
            const formdata = new FormData()
            formdata.append("image", ev.target.files[0])
            const options = {
              method: "post",
              headers: {
                  Authorization: "Client-ID 4ac8f2bf226bf8e014d6e3a65ecbaf2eaa000840"
              },
              body: formdata
          }
            fetch("https://api.imgur.com/3/image/", options).then(data => data.json()).then(data => {
                // img.src = data.data.lin
                // url.innerText = data.data.link
                console.log(data)
            })
        }}></input>
      </div>
      </div> */}
    </div>
  );
}

export default Settings;
