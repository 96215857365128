import React from "react";
import FeedbackObject from "./FeedbackObject";
import Button from "./Common/Button";
function PersonalData({
  feedback,
  username,
  fullName,
  userScore,
  profilePicture,
  logout,
}) {
  return (
    <div id="personalData">
      <div className="grid grid-cols-1">
        <div id="profile-tab" className="flex width-full">
          {/* Profile Picture */}
          <div className="flex  p-4 justify-between width-full">
            <div className=" flex p-4" id="profile-tab-inner">
              {/* <div className="flex  "> */}
              <img
                src={profilePicture}
                alt=""
                className="w-10 h-10 relative flex object-cover rounded-full"
              />
              {/* </div> */}

              <div>
                {/* Create 2 rows, the username, the score and  */}
                <div className="grid grid-cols-1 justify-start score-second">
                  <div>Points: {userScore}</div>
                  <div>Username: {username.replace(/_[0-9]*/g, "")}</div>
                </div>
              </div>
            </div>
            <div style={{ alignSelf: "center" }}>
              {/* <Button title="Log out" handleAction={logout}></Button> */}
            </div>
          </div>
        </div>
        <div id="feedback">
          <h1>My Feed</h1>
          <div>
            <br></br>
          </div>
          <div className="feedback-container">
            <FeedbackObject feedback={feedback}></FeedbackObject>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default PersonalData;
