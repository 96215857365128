import React from "react";
import { Card } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// export default function BasicCard() {
//   return (
//     <Card sx={{ minWidth: 275 }}>
//       <CardContent>
//         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
//           Word of the Day
//         </Typography>
//         <Typography variant="h5" component="div">
//           Hello
//         </Typography>
//         <Typography sx={{ mb: 1.5 }} color="text.secondary">
//           adjective
//         </Typography>
//         <Typography variant="body2">
//           well meaning and kindly.
//           <br />
//           {'"a benevolent smile"'}
//         </Typography>
//       </CardContent>
//       <CardActions>
//         <Button size="small">Learn More</Button>
//       </CardActions>
//     </Card>
//   );
// }

function FeedbackObject({ feedback }) {
  console.log(feedback);

  return (
    // <div>
    <div className="">
      {feedback !== undefined ? (
        feedback.map((value, index) => (
          <>
          <Card className="width-full card">
            <CardContent>
              <div className="left-align">
              <Typography
                sx={{ fontSize: 20 }}
                color="text.secondary"
                gutterBottom
              >
                Entry on {value.date}
              </Typography>
              <Typography variant="h5" component="div">
                {value.score}pts
              </Typography>
              {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Feedback
              </Typography> */}
              </div>
                <br></br>
              <div className="p-2 basic-typography">
              <Typography variant="body">
                {value.feedback}
              </Typography>
              </div>
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
          <br></br>
          </>
        ))
      ) : (
        <div>No Feedback Yet! </div>
      )}
      <div className="feedback-object"></div>
      {/* <div className="feedback-object">
            
        </div> */}
    </div>
    // </div>
  );
}

export default FeedbackObject;
